* {
  font-family: "Sorts Mill Goudy";
  box-sizing: border-box;
}


.text-error {
  padding-left: 10px;
  color: red;
  font-size: 11px;
}

.slick-dots {
  position: relative;
  top: -85px;
}

.slick-next {
  right: 40px;
  z-index: 99;
}

.slick-prev {
  left: 20px;
  z-index: 99;
}

.slick-slider {
  overflow: hidden;
  height: 100%;
}

.slick-slide>div {
  width: 100%;
  height: 100%;
  max-height: 600px;
}

.slick-slide>div>img {
  width: 100%;
}

/* property details css */

 

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}  
 

.MuiPickersDay-root.Mui-disabled {
  background: #eaeaea;
}

.header-form .MuiOutlinedInput-notchedOutline {
  border: 0
}

.header-form svg.MuiSelect-icon{
  display: none;
}
.headline-overlay {
  position: absolute;
  top: 30%;
  left: 0;
  transform: translateY(-50%);
  background: transparent;
  text-align: left;
  padding: 10px;
  width: 100%;
}

.image-container {
  padding: 50px;
  position: relative;
  width: 100%;
  height: 90vh;
}

.custom-img {
  width: 100%;
  border-radius: 80px;
  height: 100vh;
  vertical-align: middle;

}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x)* .5);
  padding-left: calc(var(--bs-gutter-x)* .5);
  margin-right: auto;
  margin-left: auto;
}

.about-section {
  background: #0D1B2A;
  padding: 60px 5%;
}

.align-items-center {
  align-items: center !important;
}
.justify-content-center {
  justify-content: center !important;
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex
;
  flex-wrap: wrap;
  margin-top: calc(-1* var(--bs-gutter-y));
  margin-right: calc(-.5* var(--bs-gutter-x));
  margin-left: calc(-.5* var(--bs-gutter-x));
}

.position-relative {
  position: relative !important;
}
@media (min-width: 768px) {
  .col-md-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
  }
}
.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x)* .5);
  padding-left: calc(var(--bs-gutter-x)* .5);
  margin-top: var(--bs-gutter-y);
}
.headline {
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
  margin: 0;
  line-height: 1.2;
  text-transform: uppercase;
}

.headline span {
  color: #f15b63;
  padding: 5px 10px;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}
@media (min-width: 768px) {
  .col-md-6 {
      flex: 0 0 auto;
      width: 50%;
  }
}

.content-section {
  padding-left: 50px;
  transform: translate(-50px);
}

.section-title {
  font-size: 1.8rem;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  margin-top: 20px;
}

.section-title span {
  color: #f15b63;
}

.content-section p {
  text-align: justify;
  color: #fff;
}

.slick-header {
  height: "100vh"
}

.slick-header .slick-list {
  height: 100vh
}

.home-silder {
  overflow: visible!important;
}

.home-silder .slick-prev {
  left: -30px;
}

.home-silder .slick-next {
  right: -15px;
}

.home-silder .slick-prev:before, .home-silder .slick-next:before {
  color: #fafafa!important;
}

.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper {
  max-height: 300px; 
}